import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { MediaStatus, MediaType } from '../../shared/enums/media-status.enum';

import { SculptureMediaURLV3 } from '../../shared/interfaces/response-v3.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sculpture-v2-chanel',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './sculpture-v2-chanel.component.html',
  styleUrl: './sculpture-v2-chanel.component.scss'
})


export class SculptureV2ChanelComponent {

    constructor(private activatedRoute: ActivatedRoute) { }
  
    isLoading: boolean = true;
    public MediaType = MediaType;
    mediaParam: string | null = null;
    printUrl: string = null;
    animationUrl: string = null;
  
    ngOnInit(): void {
      // Accessing the 'media' query parameter
      this.activatedRoute.queryParams.subscribe((params) => {
        this.mediaParam = params['media'];
        console.log(this.mediaParam); // Prints the value of 'media' query param
  
        const splitted = this.mediaParam.split(',');
        for(let i = 0; i < splitted.length; i++) {
          if(splitted[i].includes('print')) {
            this.printUrl = splitted[i + 1];
          } else if(splitted[i].includes('animation')) {
            this.animationUrl = splitted[i + 1];
          }
        }
  
        console.log(this.printUrl); // Prints the value of 'print' query param
        console.log(this.animationUrl); // Prints the value of 'gif' query param
  
      });
    }

}
