<div class="content-wrapper">
    <div class="container d-flex flex-column justify-content-between align-items-center">
        <div class="row text-center d-flex justify-content-between align-items-center" style="height: 80vh">
            <div class="col-12 p-5 pb-3 pt-3 ">
                <small><strong class="font-century header-text">CHOOSE YOUR FILES BELOW</strong></small>
            </div>
            <div class="col-12">
                <button class="btn btn-outline-dark btn-download font-century mb-5" *ngIf="printUrl">
                    PHOTO
                </button>
                <br>
                <button class="btn btn-outline-dark btn-download font-century mb-5" *ngIf="animationUrl">
                    GIF
                </button>
            </div>
        </div>
    </div>
</div>