export enum MediaStatus {
    UNDEFINED = 'UNDEFINED',
    PENDING = 'PENDING',
    READY = 'READY',
}

export enum MediaType {
    IMAGE = 'image',
    VIDEO = 'video',
    ANIMATION = 'animation',
}