import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error-not-found',
  standalone: true,
  imports: [],
  templateUrl: './error-not-found.component.html',
  styleUrl: './error-not-found.component.scss'
})
export class ErrorNotFoundComponent {

  env = environment.production ? 'production' : 'development';

}
