import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { SculptureMediaURLV3 } from '../../shared/interfaces/response-v3.interface';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getMediaUrlById(id: string): Observable<SculptureMediaURLV3[]> {
    return this.httpClient.get<SculptureMediaURLV3[]>(`${environment.apiEndpointV3}/spaces/medias/${id}`);
  }
}
